
import { Component, Vue } from 'vue-property-decorator';

// Config
import { RouteNames } from "@/config/router.config";
import { track, TrackingEventType } from "@/config/tracking.config";

// Resources
import { getFrontPageCollections } from "@/resources/collections.resource";
import { getProductsByCollection } from '@/resources/products.resource';

// Components
import Carousel from '../components/carousel.component.vue';
import CollectionsGrid from '../components/collections-grid.component.vue';
import HomeBanner from "@/components/home-banner.component.vue";
import ProductTile from '../components/product-tile.component.vue';

// Models
import CollectionModel from "@/models/collection.model";
import ProductModel from "@/models/product.model";

@Component({
  components: {
    Carousel,
    CollectionsGrid,
    HomeBanner,
    ProductTile,
  },
})
export default class Home extends Vue {
  collections: CollectionModel[] = [];
  products: ProductModel[] = [];

  protected created() {
    this.loadShowcaseCollections();
    this.loadShowcaseProducts();

    track(TrackingEventType.ViewContent, {
      content_category: RouteNames.Home
    });
  }

  loadShowcaseCollections() {
    getFrontPageCollections()
      .then(collections => this.collections = collections)
  }

  loadShowcaseProducts() {
    getProductsByCollection('frontpage')
    .then(products => this.products = products);
  }
}
