
import { Component, Vue } from 'vue-property-decorator';

// Models
import CollectionModel from "@/models/collection.model";

// Resources
import { getBannerCollections } from "@/resources/collections.resource";

@Component
export default class HomeBanner extends Vue {

  collections: CollectionModel[] = [];

  currentBanner = 0;
  currentTransition = 'transition-fade-right';

  scrollInterval: number | null = null;

  get banners() {
    return this.collections
        .map(collection => ({
          name: this.$t('Components.HomeBanner.CollectionName', { name: collection.localizedTitle }),
          imageUrl: collection.image,
          buttonLabel: this.$t('Components.HomeBanner.LinkLabel'),
          to: {
            name: 'Collection',
            params: {
              collectionId: collection.id
            }
          }
        }))
  }

  protected created() {
    this.loadBannerCollections();

    // Setup automatic scroll interval
    this.scrollInterval = setInterval(() => {
      this.nextBanner(true);
    }, 3000);
  }

  loadBannerCollections() {
    getBannerCollections()
      .then(collections => this.collections = collections);
  }

  goToPage(index) {
    if (this.scrollInterval !== null) {
      clearInterval(this.scrollInterval);
      this.scrollInterval = null;
    }

    if (index !== this.currentBanner) {
      this.currentTransition = index < this.currentBanner ? 'transition-fade-right' : 'transition-fade-left';
      this.currentBanner = index;
    }
  }

  previousBanner() {
    this.currentBanner--;
    this.currentTransition = 'transition-fade-right';

    if (this.currentBanner < 0) {
      this.currentBanner = this.collections.length - 1;
    }
  }

  nextBanner(fromInterval = false) {
    if (!fromInterval && this.scrollInterval !== null) {
      clearInterval(this.scrollInterval);
      this.scrollInterval = null;
    }

    this.currentBanner++;
    this.currentTransition = 'transition-fade-left';

    if (this.currentBanner >= this.collections.length) {
      this.currentBanner = 0;
    }
  }

}
