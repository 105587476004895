
import { Component, Prop, Vue } from 'vue-property-decorator';

// Config
import { RouteNames } from "@/config/router.config";

// Models
import CollectionModel from "../models/collection.model";

@Component
export default class CollectionsGrid extends Vue {
  @Prop({ type: Array, required: true})
  collections!: CollectionModel[];

  RouteNames = RouteNames;
}
